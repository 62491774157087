/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-authentication.jpg";
//API Endspoint
import API_BASE_URL from "config";

function Cover() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const { email } = useParams();
  const { token } = useParams();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const validationErrors = validate(formData);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(API_BASE_URL + "/reset_password", {
          email: email,
          token: token,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
        });
        if (response.data.success === true) {
          setSuccess(response.data.message);
          setError("");
          navigate("/sign-in?status=3");
        } else if (response.data.success === false) {
          setError(response.data.message);
          setSuccess("");
        }
        setLoading(false);
      } catch (error) {
        //console.error("Login failed:", error);
        setError(error.response.data.message);
        setSuccess("");
        setLoading(false);
      }
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Password is required.";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (values.password != values.confirmPassword) {
      errors.confirmPassword = "Confirm Password does not match.";
    }
    return errors;
  };
  return (
    <CoverLayout image="">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={3}
          mb={0}
          textAlign="center"
        >
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter the new password you want to set
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {error && (
            <MDTypography display="block" variant="h6" color="error" mb={1}>
              {error}
            </MDTypography>
          )}
          {success && (
            <MDTypography display="block" variant="h6" color="success" mb={1}>
              {success}
            </MDTypography>
          )}
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                name="password"
                type="password"
                label="Password"
                variant="standard"
                value={formData.password}
                onChange={handleChange}
                autoFocus
                fullWidth
                error={formErrors.password ? true : false}
              />
              {formErrors.password && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {formErrors.password}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                variant="standard"
                value={formData.confirmPassword}
                onChange={handleChange}
                autoFocus
                fullWidth
                error={formErrors.password ? true : false}
              />
              {formErrors.confirmPassword && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {formErrors.confirmPassword}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                reset
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Want to Sign In?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Click Here
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
