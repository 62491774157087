/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//API Endspoint
import API_BASE_URL from "config";

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const token = userData ? userData.token : "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const validationErrors = validate(formData);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          API_BASE_URL + "/change_password",
          {
            current_password: formData.currentPassword,
            new_password: formData.newPassword,
          },
          { headers }
        );
        if (response.data.success == true) {
          setSuccess(response.data.message);
        } else {
          setError(response.data.message);
        }
        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        setLoading(false);
      }
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.currentPassword) {
      errors.currentPassword = "Current Password is required.";
    }
    if (!values.newPassword) {
      errors.newPassword = "New Password is required.";
    }
    if (!values.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm New Password is required.";
    } else if (values.newPassword != values.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm New Password does not match.";
    }
    return errors;
  };
  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {error && (
          <MDTypography display="block" variant="h6" color="error" mb={2}>
            {error}
          </MDTypography>
        )}
        {success && (
          <MDTypography display="block" variant="h6" color="success" mb={2}>
            {success}
          </MDTypography>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              fullWidth
              label="Current Password"
              inputProps={{ type: "password", autoComplete: "" }}
              error={formErrors.currentPassword ? true : false}
            />
            {formErrors.currentPassword && (
              <MDTypography display="block" variant="button" color="error" mb={0}>
                {formErrors.currentPassword}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              fullWidth
              label="New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              error={formErrors.newPassword ? true : false}
            />
            {formErrors.newPassword && (
              <MDTypography display="block" variant="button" color="error" mb={0}>
                {formErrors.newPassword}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="confirmNewPassword"
              value={formData.confirmNewPassword}
              onChange={handleChange}
              fullWidth
              label="Confirm New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              error={formErrors.confirmNewPassword ? true : false}
            />
            {formErrors.confirmNewPassword && (
              <MDTypography display="block" variant="button" color="error" mb={0}>
                {formErrors.confirmNewPassword}
              </MDTypography>
            )}
          </Grid>
        </Grid>
        {/* <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox> */}
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          {/* <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox> */}
          <MDBox ml="auto" mt={1}>
            <MDButton variant="gradient" onClick={handleSubmit} color="info" size="small">
              update password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
