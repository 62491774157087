import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userToken = userData ? userData.token : null;
  let auth = { token: userToken ? true : false };
  return auth.token ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoutes;
