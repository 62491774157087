import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-authentication.jpg";

import axios from "axios";
//API Endspoint
import API_BASE_URL from "config";
import { models } from "powerbi-client";
import { useReportConfig } from "ReportConfigContext";

function Cover() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [reSendLink, setReSendLink] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const { setReportConfig } = useReportConfig();
  const fetchReportsData = async () => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const userAzureData = userData.azure_functions ? userData.azure_functions : [];
    const fetchedReports = await Promise.all(
      userAzureData.map(async (azrData) => {
        try {
          const res = await fetch(azrData.function_reference);
          const data = await res.json();
          return { identifier: azrData.report_slug, function_name: azrData.function_name, data };
        } catch (error) {
          console.error(`Error fetching data for ${azrData.report_slug}:`, error);
          return {
            identifier: azrData.report_slug,
            function_name: azrData.function_name,
            data: null,
          };
        }
      })
    );
    // Prepare report configuration for derived data of all azure function
    const reportConfigs = fetchedReports
      .filter((pbreport) => pbreport.data !== null)
      .map((pbreport) => ({
        pages: [],
        report_slug: pbreport.identifier,
        function_name: pbreport.function_name,
        id: pbreport.data.reportId,
        embedUrl: pbreport.data.embedUrl,
        accessToken: pbreport.data.embedToken,
      }));
    setReportConfig(reportConfigs);
    navigate(`/${userAzureData[0].report_slug}`);
    // Hide loader after successful submission
    setLoading(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleResendRequest = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(API_BASE_URL + "/resend_verification_email", {
        email: formData.email,
      });
      setSuccess(response.data.message);
      setError("");
      setReSendLink(false);
      setLoading(false);
    } catch (error) {
      if (error.response !== null) {
        setError(error.response.data.message);
        setSuccess("");
        setLoading(false);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setReSendLink(false);
    const validationErrors = validate(formData);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(API_BASE_URL + "/login", {
          email: formData.email,
          password: formData.password,
        });
        sessionStorage.setItem("userData", JSON.stringify(response.data.user));
        // Fetch initial report data
        fetchReportsData();
      } catch (error) {
        console.error("Login failed:", error);
        if (error.response !== null) {
          if (error.response.data.email_not_verified) {
            setReSendLink(true);
          }
          setError(error.response.data.message);
          setSuccess("");
          // Hide loader in case of error
          setLoading(false);
        }
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regexe = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regexe.test(values.email)) {
      errors.email = "This is not valid email format";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };
  useEffect(() => {
    const status = searchParams.get("status");
    if (status == 1) {
      setSuccess("Your email has been verified successfully.");
    } else if (status == 2) {
      setSuccess("Your email is already verified.");
    } else if (status == 0) {
      setError("Invalid Token.");
    } else if (status == 3) {
      setSuccess("Your password has been changed successfully!");
    }
  }, [searchParams]);
  return (
    <CoverLayout image="">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={0}
          textAlign="center"
        >
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open ? true : false}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {error && (
            <MDTypography display="block" variant="h6" color="error" mb={1}>
              {error}
              {reSendLink && (
                <MDTypography variant="button" color="info">
                  <MDTypography
                    component={Link}
                    //to="/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    onClick={handleResendRequest}
                    textGradient
                  >
                    Click here
                  </MDTypography>{" "}
                  to resend verification email.
                </MDTypography>
              )}
            </MDTypography>
          )}
          {success && (
            <MDTypography display="block" variant="h6" color="success" mb={1}>
              {success}
            </MDTypography>
          )}
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                name="email"
                type="email"
                label="Email"
                variant="standard"
                value={formData.email}
                onChange={handleChange}
                required
                autoFocus
                fullWidth
                error={formErrors.email ? true : false}
              />
              {formErrors.email && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {formErrors.email}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                name="password"
                type="password"
                label="Password"
                variant="standard"
                value={formData.password}
                onChange={handleChange}
                required
                fullWidth
                error={formErrors.password ? true : false}
              />
              {formErrors.password && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {formErrors.password}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disable={loading ? 1 : 0}
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/forget-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot password?
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
