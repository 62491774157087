import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-authentication.jpg";

import axios from "axios";

//API Endspoint
import API_BASE_URL from "config";

function Cover() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const validationErrors = validate(formData);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(API_BASE_URL + "/signup", {
          first_name: formData.firstname,
          last_name: formData.lastname,
          email: formData.email,
          password: formData.password,
        });
        if (response.data.success == true) {
          setSuccess(response.data.message);
        } else {
          setError(response.data.message);
        }
        setLoading(false);
      } catch (error) {
        setError(error.response.data.message);
        setLoading(false);
      }
    }
  };
  const validate = (values) => {
    const errors = {};
    const regexe = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstname) {
      errors.firstname = "First Name is required.";
    }
    if (!values.lastname) {
      errors.lastname = "Last Name is required.";
    }
    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!regexe.test(values.email)) {
      errors.email = "This is not valid email format.";
    }
    if (!values.password) {
      errors.password = "Password is required.";
    }
    return errors;
  };

  return (
    <CoverLayout image="">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={0}
          textAlign="center"
        >
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {error && (
            <MDTypography display="block" variant="h6" color="error" mb={1}>
              {error}
            </MDTypography>
          )}
          {success && (
            <MDTypography display="block" variant="h6" color="success" mb={1}>
              {success}
            </MDTypography>
          )}
          <MDBox component="form" role="form">
            <MDBox mb={2} display="flex">
              <MDBox mr={2} flex={1}>
                <MDInput
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  error={formErrors.firstname ? true : false}
                  type="text"
                  label="First Name"
                  variant="standard"
                  required
                  fullWidth
                  autoFocus
                />
                {formErrors.firstname && (
                  <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                    {formErrors.firstname}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mr={2} flex={1}>
                <MDInput
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  error={formErrors.lastname ? true : false}
                  type="text"
                  label="Last Name"
                  variant="standard"
                  required
                  fullWidth
                />
                {formErrors.lastname && (
                  <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                    {formErrors.lastname}
                  </MDTypography>
                )}
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={formErrors.email ? true : false}
                type="email"
                label="Email"
                variant="standard"
                required
                fullWidth
              />
              {formErrors.email && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {formErrors.email}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={formErrors.password ? true : false}
                type="password"
                label="Password"
                variant="standard"
                required
                fullWidth
              />
              {formErrors.password && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {formErrors.password}
                </MDTypography>
              )}
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
