/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-authentication.jpg";
//API Endspoint
import API_BASE_URL from "config";

function Cover() {
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [success, setSuccess] = useState("");
  const [email, setEmail] = useState("");
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationError = "";
    setEmailError("");
    const regexe = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      validationError = "Email is required.";
    } else if (!regexe.test(email)) {
      validationError = "This is not Valid email format";
    }
    setEmailError(validationError);
    if (!validationError) {
      setLoading(true);
      try {
        const response = await axios.post(API_BASE_URL + "/forget_password", {
          email: email,
        });
        setSuccess(response.data.message);
        setLoading(false);
      } catch (error) {
        //console.error("Login failed:", error);
        setEmailError(error.response.data.message);
        setLoading(false);
      }
    }
  };

  return (
    <CoverLayout image="">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={3}
          mb={0}
          textAlign="center"
        >
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {success && (
            <MDTypography display="block" variant="h6" color="success" mb={1}>
              {success}
            </MDTypography>
          )}
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                name="email"
                type="email"
                label="Email"
                variant="standard"
                value={email}
                onChange={handleChange}
                autoFocus
                fullWidth
                error={emailError ? true : false}
              />
              {emailError && (
                <MDTypography display="block" variant="button" style={{ color: "red" }} mb={1}>
                  {emailError}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                reset
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Remember password?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
