// src/ConfigContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

const ReportConfigContext = createContext();

export const ReportConfigProvider = ({ children }) => {
  const [reportConfig, setReportConfig] = useState(() => {
    const storedConfig = sessionStorage.getItem("reportConfig");
    return storedConfig ? JSON.parse(storedConfig) : null;
  });

  // Update local storage when report configuration changes
  useEffect(() => {
    sessionStorage.setItem("reportConfig", JSON.stringify(reportConfig));
  }, [reportConfig]);

  return (
    <ReportConfigContext.Provider value={{ reportConfig, setReportConfig }}>
      {children}
    </ReportConfigContext.Provider>
  );
};

ReportConfigProvider.propTypes = {
  children: PropTypes.node,
};

export const useReportConfig = () => {
  return useContext(ReportConfigContext);
};
